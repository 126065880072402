import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VCalendar } from 'vuetify/labs/VCalendar'  // Changed import path

const defaultTheme = {
  dark: false,
  colors: {
    primary: '#eb5c37',
    secondary: '#40424c',
    tertiary: '#f0f0f0',
    title: "#9e9e9e",
    featureHighlight: "#9e9e9e",
    dialogColor: '#eb5c37',
    accent: '#9e9e9e',
    error: '#b71c1c',
    tabColor: '#eb5c37',
    tableColor: '',
    fontColor: '#36454F',
  },
  options: {
    customProperties: true
  }
}

export default createVuetify({
  components: {
    ...components,  // Spread the base components
    VCalendar,     // Add VCalendar explicitly
  },
  directives,
  theme: {
    defaultTheme: 'defaultTheme',
    themes: {
      defaultTheme,
    }
  }
})