import { API } from "aws-amplify";

export default {
    namespaced: true,
    state: {
        activeTenant: null
    },
    mutations: {
        SET_ACTIVE_TENANT(state, data) {
            state.activeTenant = data;
        }
    },
    getters: {
        activeTenant(state) {
            return state.activeTenant;
        }
    },
    actions: {
        getActiveTenant({ commit }, payload) {
            const params = {
                body: {
                    clientName: payload.clientName,
                    tenantID: payload.tenantID
                }
            };
            
            return new Promise((resolve, reject) => {
                API.post("peoplePortalRestAPI", "/accounts/tenant/active", params)
                    .then((response) => {
                        if (response.status === "200") {
                            commit("SET_ACTIVE_TENANT", response.data);
                            resolve({
                                success: true,
                                data: response.data
                            });
                        } else {
                            reject({
                                success: false,
                                error: response.error
                            });
                        }
                    })
                    .catch((error) => {
                        reject({
                            success: false,
                            error: error.message
                        });
                    });
            });
        }
    }
}