import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // Generic Route
  {
    path: '/', 
    name: 'ErrorView',
    component: () => import('../views/ParentViews/ErrorView'),
    children: [
    {
      path: '/company-not-found',
      name: 'CompanyNotFoundError',
      component: () => import('../views/static/CompanyNotFoundView.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404View',
      component: () => import('../views/static/404View.vue'),
    },
    {
      path: '/500',
      name: '500View',
      component: () => import('../views/static/500View.vue'),
    },
  ]
  },
  // Company Route
  {
    path: '/:company',
    name: 'CompanyHome',
    component: () => import('../views/ParentViews/CompanyHomeView.vue'),
    children: [{
      path: '/:company/accounts/',
      name: 'AccountsView',
      component: () => import('../views/AccountsViews/AccountsView.vue'),
    },
    {
      path: '/:company/SalesVendor',
      name: 'vendorView',
      component: () => import('../views/ParentViews/VendorMasterView.vue'),
      children:[{
        path: '/:company/SalesVendor/:id',
        name: 'VendorAccountView',
        component: () => import('../views/VendorViews/VendorAccountView.vue'),
      }]
    },
    {
      path: '/:company/SalesApplicantBuyer',
      name: 'buyerView',
      component: () => import('../views/ParentViews/BuyerMasterView.vue'),
      children:[{
        path: '/:company/SalesApplicantBuyer/:id',
        name: 'BuyerAccountView',
        component: () => import('../views/BuyerViews/BuyerAccountView.vue'),
      }]
    },
    {
      path: '/:company/LettingsLandlord',
      name: 'landlordView',
      component: () => import('../views/ParentViews/LandlordMasterView.vue'),
      children:[{
        path: '/:company/LettingsLandlord/:id',
        name: 'LandlordAccountView',
        component: () => import('../views/LandlordViews/LandlordAccountView.vue'),
      }]
    },
    {
      path: '/:company/LettingsTenant',
      name: 'tenantView',
      component: () => import('../views/ParentViews/TenantMasterView.vue'),
      children:[{
        path: '/:company/LettingsTenant/:id',
        name: 'ActiveTenantView',
        component: () => import('../views/TenantViews/Active/ActiveTenantView.vue'),
      }]
    },
    {
      path: '/:company/:accountType/messaging-centre/:personOID',
      name: 'MessagingCentreView',
      component: () => import('../views/MessagingCentre/MessagingCentreView.vue')
    }
  ]
  }
]
  

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
